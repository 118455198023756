<template>
  <div style="background-color: #f0f2f5">
    <div
        style="
        width: 100%;
        height: 64px;
        margin-bottom: 10px;
        background-color: #ffffff;
        line-height: 64px;
      "
    >
      <div
          style="
          width: 120px;
          line-height: 64px;
          margin: 0 20px;
          display: inline-block;
        "
      >
        <a-button type="primary" @click="addArticle()">添加文章</a-button>
      </div>
    </div>
    <div class="tree-table">
      <a-table :dataSource="articleData" :columns="columns" :pagination="false">
        <template #headerCell="{ column }">
          <div style="text-align: center">
            {{ column.title }}
          </div>
        </template>

        <!--        空数据-->
        <template #emptyText>
          <a-empty v-if="articleData.length === 0" :image="simpleImage">
            <template #description>
              <span style="color: #c5c5c5"> 暂无数据 </span>
            </template>
            <a-button
                style="margin-top: 20px"
                type="primary"
                @click="addArticle()"
            >添加文章
            </a-button
            >
          </a-empty>
        </template>

        <!--        有数据-->
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'id'">
            <div style="text-align: center">
              {{ record.id }}
            </div>
          </template>
          <template v-if="column.key === 'cover'">
            <div style="text-align: center;width: 80px;height: 45px">
              <img :src="record.cover" alt="" style="width: 100%;height: 100%;object-fit: cover">
            </div>
          </template>
          <template v-else-if="column.key === 'title'">
            <span>
              {{ record.title }}
            </span>
          </template>
          <template v-else-if="column.key === 'cate_title'">
            <span>
              {{ record.parent_cate_title }} <br> {{ record.cate_title }}
            </span>
          </template>
          <template v-else-if="column.key === 'status'">
            <div style="text-align: center">
              <a-tag v-if="record.status === 0" type="default"> 草稿</a-tag>
              <a-tag v-else color="success"> 发布</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <div style="text-align: center">
              <a-button
                  type="link"
                  style="display: inline-block; padding: 0 5px"
                  @click="editArticle(record.id)"
              >编辑
              </a-button>
              <a-button
                  type="link"
                  danger
                  style="display: inline-block; padding: 0 5px"
                  @click="deleteArticle(record.id,record.title)"
              >
                删除
              </a-button>
            </div>
          </template>

        </template>
      </a-table>

      <a-pagination style="padding: 20px 70px;" :responsive="true" :current="page" :total="total" :pageSize="limit" :showSizeChanger="true" :pageSizeOptions="pageSizeOptions" @change="changePage"/>

    </div>
  </div>
</template>

<script>
import {Button, Empty, Table, Tag, Modal, message,Pagination} from "ant-design-vue/es";
import article from "../../../api/article";
import router from "../../../router";

export default {
  name: "List",
  components: {
    APagination:Pagination,
    AButton: Button,
    AEmpty: Empty,
    ATable: Table,
    ATag: Tag,
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE, //空数据图片
      articleData: [],
      addData: {},
      page: 1,
      total:0,
      limit:10,
      pageSizeOptions:['10', '20', '30', '40'],
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "封面",
          dataIndex: "cover",
          key: "cover",
          width: 100,
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
        },

        {
          title: "分类",
          dataIndex: "cate_title",
          key: "cate_title",
          width: 160
        },

        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 80
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
        },
      ],
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    //获取文章列表
    getArticle() {

      const that = this
      let data = {page: that.page, limit: that.limit};
      console.log('getArticle');
      console.log(data)
      article
          .getArticles(data)
          .then((res) => {
            if (res.code === 0) {
              that.articleData = res.data.data;
              that.total = res.data.total
            } else {
              console.log(res);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    //添加文章
    addArticle() {
      router.push({
        path: "edit"
      });
    },
    //编辑文章
    editArticle(id){
      router.push({
        path: "edit",
        query:{
          'id':id
        }
      });
    },
    //删除文章
    deleteArticle(id, title) {
      const that = this
      Modal.confirm({
        title: '您确定要删除此文章？',
        content: title,
        okText: '删除',
        okType: 'danger',
        cancelText: '我再想想',
        onOk() {
          article.deleteArticle(id).then((res) => {
            if (res.code === 0) {
              message.success(res.msg)
              that.getArticle();
            } else {
              console.log(res);
            }
          }).catch((error) => {
            console.log(error);
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },

    //点击分页页码
    changePage(page, pageSize){
      this.page = page
      this.limit = pageSize
      console.log(this.page)
      console.log(this.limit)
      this.getArticle();
    }
  },
};
</script>

<style scoped>
.tree-table {
  overflow: scroll;
  height: calc(100vh - 267px);
  padding: 20px;
  position: relative;
  background-color: #ffffff;
}

</style>
